import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from "react-ga4";

import BaseLayout from "./layouts/BaseLayout/BaseLayout";
import LoadingBox from "./components/LoadingBox/LoadingBox";
import app_params from "./config/params";

const HomeScreen = React.lazy(() => import('./screens/HomeScreen/HomeScreen'));
const AdvancedSearchScreen = React.lazy(() => import('./screens/AdvancedSearchScreen/AdvancedSearchScreen'));
const SearchResultsScreen = React.lazy(() => import('./screens/SearchResultsScreen/SearchResultsScreen'));
const RecordScreen = React.lazy(() => import('./screens/RecordScreen/RecordScreen'));
const NotFoundScreen = React.lazy(() => import('./screens/NotFoundScreen/NotFoundScreen'));
const AcquisitionScreen = React.lazy(() => import('./screens/AcquisitionScreen/AcquisitionScreen'));
const HistoryScreen = React.lazy(() => import('./screens/HistoryScreen/HistoryScreen'));
const MissionScreen = React.lazy(() => import('./screens/MissionScreen/MissionScreen'));
const VisionScreen = React.lazy(() => import('./screens/VisionScreen/VisionScreen'));

const App = () => {

  useEffect(() => {
    ReactGA.initialize(app_params.gaTag);

    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter basename="/opac">
      <BaseLayout>
        {/* Common routes */}
        <Suspense fallback={<LoadingBox />}>
          <Routes>
            <Route exact path="/" element={<HomeScreen />} />
            <Route exact path="/acquisitions" element={<AcquisitionScreen />} />
            <Route exact path="/history" element={<HistoryScreen />} />
            <Route exact path="/mission" element={<MissionScreen />} />
            <Route exact path="/vision" element={<VisionScreen />} />
            <Route exact path="/advancedsearch" element={<AdvancedSearchScreen />} />
            <Route exact path="/query" element={<SearchResultsScreen />} />
            <Route path="/record/:id" element={<RecordScreen />} />
            <Route path='/404' element={<NotFoundScreen />} />
            <Route exact path='*' element={<NotFoundScreen />} />
          </Routes>
        </Suspense>
      </BaseLayout>
    </BrowserRouter>
  );
};

export default App;