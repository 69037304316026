import React from "react";

import app_params from "../../config/params";

const Navbar = (props) => {
  return (
    <header>
      <nav className={`navbar navbar-expand-md navbar-dark theme-navbar`}>
        <div className="container-fluid">
          <a className="navbar-brand" href={`${app_params.basepath}`}>
            <img
              src={`${app_params.basepath}/assets/logos/brand.png`}
              alt="brand-logo"
              className="d-inline-block align-middle brand-logo"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-lg-2 mt-3 mt-lg-0">
              <li className="nav-item mx-2 px-2 px-lg-0">
                <a className="nav-link" href={`${app_params.basepath}/history`} tabIndex="-1">
                  Reseña Histórica
                </a>
              </li>
              <li className="nav-item mx-2 px-2 px-lg-0">
                <a className="nav-link" href={`${app_params.basepath}/mission`} tabIndex="-1">
                  Misión
                </a>
              </li>
              <li className="nav-item mx-2 px-2 px-lg-0">
                <a className="nav-link" href={`${app_params.basepath}/vision`} tabIndex="-1">
                  Visión
                </a>
              </li>
            </ul>
            <div className="collapse navbar-collapse d-flex flex-row-reverse me-4">
              <h5>Grande entre las grandes</h5>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
